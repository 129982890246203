import { useGetPropertyContactQuery } from '@updater/consumer-graph';
import { useGetDivisionId } from './use-get-divisionId';
import { useMemo } from 'react';

/**
 * Custom hook that retrieves property contact information.
 *
 * @returns An object containing property contact details, a flag indicating if any contact details are missing, and a loading flag.
 */
export const usePropertyContact = () => {
  const { divisionId, loading: divisionLoading } = useGetDivisionId();
  const { data: propertyData, loading: propertyDataLoading } =
    useGetPropertyContactQuery({
      variables: {
        divisionId: divisionId,
      },
      skip: !divisionId,
    });
  const { name = null, address } = propertyData?.division ?? {};

  const {
    street = null,
    unit = null,
    city = null,
    state = null,
    postalCode = null,
  } = address ?? {};

  const missingPropertyContactDetails = !(
    name &&
    street &&
    city &&
    state &&
    postalCode
  );
  const loading = divisionLoading || propertyDataLoading;
  return useMemo(() => {
    return {
      propertyContact: {
        name,
        address: {
          street,
          unit,
          city,
          state,
          postalCode,
        },
      },
      missingPropertyContactDetails,
      loading,
    };
  }, [
    name,
    street,
    unit,
    city,
    state,
    postalCode,
    missingPropertyContactDetails,
    loading,
  ]);
};
