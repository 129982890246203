import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  SetStateAction,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { getUserInfo_getUser_user_currentMove } from 'types/generated/getUserInfo';
import { useRouter } from 'next/router';
import {
  dollyEligibility,
  dollyEligibilityVariables,
} from 'types/generated/dollyEligibility';
import { GetDollyEligibilityQuery } from 'utils/queries';
import { AddressTypeaheadInput } from '@updater/ui-widgets';
import {
  Box,
  IconButton,
  Button,
  Spinner,
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Text,
  useToast,
} from '@updater/ui-uds';
import styled from '@emotion/styled';
import { ArrowsDownUp } from '@phosphor-icons/react';
import { useDollyFlowStore } from 'flows/move-your-stuff/store';
import { AddressInput } from 'types/generated/globalTypes';
import {
  determineDollyEligibilityErrors,
  ErrorType,
  ErrorDetails,
} from 'flows/core/utils/moving';
import { useRESTClient } from 'context';
import { DollyMetadata, DollyRoute } from 'flows/move-your-stuff/types/types';
import { useTracking } from 'react-tracking';
import { TrackEventInput } from '@updater/ui-tracker';
import { ItemStatuses } from 'constants/statuses';
import { ItemKinds } from 'types';
import { useSWRWhenAuthed } from 'utils/use-swr-when-authed';
import { useTrackViewOnMount, useTrackOnMount } from 'utils/use-track-on-mount';

const SwitchButtonWrapper = styled(Box)`
  z-index: ${({ theme }) => theme.zIndices.content};
`;

export const getFormattedAddress = (address: AddressInput) => {
  const addressLine = address?.unit
    ? `${address.street}, #${address.unit}`
    : address.street;
  return `${addressLine}, ${address.city}, ${address.state}`;
};

export const getMYSFormattedAddress = (address: AddressInput) => {
  const addressLine = address?.unit
    ? `${address.street}, #${address.unit}`
    : address.street;
  return `${addressLine}, ${address.city}, ${address.state} ${address.postalCode}`;
};

const isAddressDollyEligible = (address) => {
  return Boolean(address?.eligible);
};
interface InitialValueProp {
  value: string;
  fullAddress: AddressInput;
}

const useFieldValueAndErrorState = (initialValue: InitialValueProp) => {
  const [value, setValue] = useState(initialValue);
  const [tempValue, setTempValue] = useState<string>('');
  const [errorMsg, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    setErrorMessage('');
    setTempValue(value.value);
  }, [value]);
  return [
    value,
    setValue,
    errorMsg,
    setErrorMessage,
    tempValue,
    setTempValue,
  ] as const;
};

const useSetInit = (currentMove, dataLoading, data) => {
  const { reset } = useDollyFlowStore();
  const [init, setInit] = useState<boolean>(false);
  useEffect(() => {
    setInit(true);
    // reset dolly store
    reset();

    if (!dataLoading && data) {
      setInit(false);
    }
  }, [currentMove, reset, dataLoading, data]);

  return [init] as const;
};

const useSetAddress = () => {
  const [value, setValue] = useState<AddressInput | undefined>();

  return [value, setValue] as const;
};
/**
 * this effect checks dolly eligibility on the users
 * `currentMove` to/from addresses obtained from user
 * data via the `getUserInfo` query.
 *
 *
 * only run on init
 * */
const useGetCurrenMoveEligibility = (currentMove) => {
  const [getDollyEligibility, { loading, data, error }] = useLazyQuery<
    dollyEligibility,
    dollyEligibilityVariables
  >(GetDollyEligibilityQuery);
  const fromUserAddress = useRef({} as AddressInput);
  const toUserAddress = useRef({} as AddressInput);
  const [fromAddress, setFromAddress] = useSetAddress();
  const [toAddress, setToAddress] = useSetAddress();
  const [init] = useSetInit(currentMove, loading, data);

  useEffect(() => {
    if (currentMove && init) {
      const {
        fromAddress: { state, street, unit, city, postalCode },
        toAddress: {
          state: toState,
          street: toStreet,
          unit: toUnit,
          city: toCity,
          postalCode: toPostalCode,
        },
      } = currentMove;

      fromUserAddress.current = { state, street, unit, city, postalCode };
      toUserAddress.current = {
        state: toState,
        street: toStreet,
        unit: toUnit,
        city: toCity,
        postalCode: toPostalCode,
      };

      getDollyEligibility({
        variables: {
          input: {
            addresses: [fromUserAddress.current, toUserAddress.current],
          },
        },
      });

      if (error) {
        console.warn(
          `init book movers error: ${error} from currentMove addresses`
        );
      }

      if (data?.dollyEligibility.eligible) {
        if (
          isAddressDollyEligible(data?.dollyEligibility.addressEligibility[0])
        ) {
          setFromAddress(fromUserAddress.current);
        }

        if (
          isAddressDollyEligible(data?.dollyEligibility.addressEligibility[1])
        ) {
          setToAddress(toUserAddress.current);
        }
      }
    }
  }, [
    currentMove,
    init,
    getDollyEligibility,
    data,
    loading,
    error,
    setFromAddress,
    setToAddress,
  ]);

  return {
    fromUserAddress: fromAddress,
    toUserAddress: toAddress,
    loading,
  };
};

export interface BookMoversProps {
  currentMove: getUserInfo_getUser_user_currentMove;
}

export const emptyAddressError = 'Please enter an address';

/**
 * This component is the primary entry point into the Dolly flow.
 *
 * If the user has a current move, the addresses will be checked for Dolly
 * eligibility, and if eligible will be used to pre-populate the address fields.
 *
 * A user can submit a "from" and/or "to" address to begin the flow. Upon
 * submission, the addresses will be checked for Dolly eligibility, which will
 * display error messages if not eligible. If the addresses are eligible, a
 * draft item is created with the `/v2/items` API (unless there is an existing
 * one in a completed status), and the user is taken to the next step of the
 * flow (answering questions about their move).
 *
 * @param props.currentMove The user's current move.
 */
export const BookMovers: React.FC<BookMoversProps> = ({ currentMove }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const router = useRouter();
  const initialValue: InitialValueProp = { value: '', fullAddress: null };
  const [isForceSelectFromAddress, setForceSelectFromAddress] =
    useState<boolean>(false);
  const [isForceSelectToAddress, setForceSelectToAddress] =
    useState<boolean>(false);
  const isSubmitted = useRef<boolean>(false);
  const [isLongDistanceError, setLongDistanceError] = useState<boolean>(false);
  const addresses = useRef([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    setFromAddress,
    setToAddress,
    setItemId,
    setFromHomeSize,
    setToHomeSize,
    setLocalFromAddress,
    setLocalToAddress,
    localFromAddress,
    localToAddress,
  } = useDollyFlowStore();
  const { axios } = useRESTClient();
  const toast = useToast();
  const { Track, trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: 'mys',
  });

  const { data: itemsData, error: itemsError } = useSWRWhenAuthed('/v2/items');

  const { fromUserAddress, toUserAddress, loading } =
    useGetCurrenMoveEligibility(currentMove);

  const [getDollyEligibility] = useLazyQuery<
    dollyEligibility,
    dollyEligibilityVariables
  >(GetDollyEligibilityQuery);

  const [
    fromValue,
    setFromValue,
    fromErrorMsg,
    setFromErrorMsg,
    fromInputVal,
    setFromInputVal,
  ] = useFieldValueAndErrorState(initialValue);
  const [
    toValue,
    setToValue,
    toErrorMsg,
    setToErrorMsg,
    toInputVal,
    setToInputVal,
  ] = useFieldValueAndErrorState(initialValue);

  useTrackOnMount(trackEvent, { object: 'flow_started', verb: 'sent' });
  useTrackViewOnMount(trackEvent, {
    object: 'landing',
    pageName: 'moving_tab',
  });

  const handleSelectFromAddress = useCallback(
    (address: AddressInput) => {
      const formattedAddress = getFormattedAddress(address);
      setLocalFromAddress(address);
      setFromValue({
        fullAddress: address,
        value: formattedAddress,
      });
    },
    [setFromValue, setLocalFromAddress]
  );

  const handleSelectToAddress = useCallback(
    (address: AddressInput) => {
      const formattedAddress = getFormattedAddress(address);
      setLocalToAddress(address);
      setToValue({
        fullAddress: address,
        value: formattedAddress,
      });
    },
    [setLocalToAddress, setToValue]
  );

  /**
   * if currentMove fromUserAddress and toUserAddresses are eligible
   * auto set the to/from inputs unless there is an address present
   * in persisted Dolly store
   * */
  useEffect(() => {
    if (fromUserAddress && localFromAddress === undefined) {
      handleSelectFromAddress(fromUserAddress);
    } else if (localFromAddress) {
      handleSelectFromAddress(localFromAddress);
    }

    if (toUserAddress && localToAddress === undefined) {
      handleSelectToAddress(toUserAddress);
    } else if (localToAddress) {
      handleSelectToAddress(localToAddress);
    }
  }, [
    fromUserAddress,
    handleSelectFromAddress,
    handleSelectToAddress,
    localFromAddress,
    localToAddress,
    toUserAddress,
  ]);

  const trackEligibilityError = useCallback(
    (details) => {
      trackEvent({
        object: 'entered_address_dolly_eligibilty',
        verb: 'tracked',
        details: {
          eligible: false,
          ...details,
        },
      });
    },
    [trackEvent]
  );

  const setErrorMessagesFromErrorType = (
    errorType: ErrorType,
    locationIndex,
    errorMsg,
    errorDetails: ErrorDetails
  ) => {
    const getFromAndToTrackingDetails = () => {
      return {
        dollyServiceError: errorDetails.dollyServiceError,
        fromAdress: fromValue.fullAddress,
        toAddress: toValue.fullAddress,
        fromDollyServiceError: errorDetails.fromDollyServiceError,
        fromAddressEligible: errorDetails.fromAddressEligible,
        toAddressEligible: errorDetails.toAddressEligible,
        toDollyServiceError: errorDetails.toDollyServiceError,
      };
    };
    if (errorType === 'singleLocation') {
      if (locationIndex === 0) {
        setFromErrorMsg(errorMsg as SetStateAction<string>);
        trackEligibilityError({
          addressDirection: 'from_address',
          dollyServiceError: errorDetails.fromDollyServiceError,
          address: fromValue.fullAddress,
        });
      } else if (locationIndex === 1) {
        setToErrorMsg(errorMsg as SetStateAction<string>);
        trackEligibilityError({
          addressDirection: 'to_address',
          dollyServiceError: errorDetails.toDollyServiceError,
          address: toValue.fullAddress,
        });
      } else if (addresses.current.length === locationIndex) {
        setFromErrorMsg(errorMsg as SetStateAction<string>);
        setToErrorMsg(errorMsg as SetStateAction<string>);
        trackEligibilityError({
          addressDirection: 'from_and_to',
          ...getFromAndToTrackingDetails(),
        });
      }
    }

    if (errorType === 'multipleLocation') {
      setLongDistanceError(true);
      trackEligibilityError({
        errorType: 'long_distance',
        addressDirection: 'from_and_to',
        ...getFromAndToTrackingDetails(),
      });
    }
  };

  const handleRouteToMYS = useCallback(async () => {
    let fromAddress = null;
    let toAddress = null;
    const fromHomeSize = fromValue?.fullAddress?.homeSize || null;
    const toHomeSize = toValue?.fullAddress?.homeSize || null;

    if (addresses.current.length === 2) {
      fromAddress = getMYSFormattedAddress(fromValue.fullAddress);
      toAddress = getMYSFormattedAddress(toValue.fullAddress);
      setFromAddress(fromAddress);
      setFromHomeSize(fromHomeSize || undefined);
      setToAddress(toAddress);
      setToHomeSize(toHomeSize || undefined);
    }

    if (addresses.current.length === 1) {
      if (fromValue.value && !toValue.value) {
        fromAddress = getMYSFormattedAddress(fromValue.fullAddress);
        setFromAddress(fromAddress);
        setFromHomeSize(fromHomeSize || undefined);
      } else {
        toAddress = getMYSFormattedAddress(toValue.fullAddress);
        setToAddress(toAddress);
        setToHomeSize(toHomeSize || undefined);
      }
    }

    const metadata: DollyMetadata = {
      from_address: fromAddress,
      from_home_size: fromHomeSize,
      to_address: toAddress,
      to_home_size: toHomeSize,
    };

    // Create the item with metadata
    try {
      // NOTE: this is an ugly hack to suppress drafts if the user has already converted.
      // We cannot support multiple drafts for a number of reasons, the biggest being that
      // we don't have real-time updates for Dolly conversion: a user could convert on dolly,
      // and the status of that draft would not switch to "completed" until the cron runs
      // daily.  this means a user could potentially re-enter the flow using an item that's
      // actually in a converted state.
      // there are other issues as well: the /v2/items endpoint is actually an upsert, not a
      // true insert, so we can't really use it all.  there's a mutation that supports multiple
      // drafts, but it's not coordinated with the dollyMove mutation which is required for the
      // cron to run.  basically, this whole thing needs to be refactored from the ground up
      // WITH the Dolly team so we can solve the problem holistically.
      let createDraft = true;
      if (itemsData && itemsError === undefined) {
        const mysCompletedItems = itemsData.filter((item) => {
          return (
            item.kind === 'move_your_stuff' &&
            ['completed', 'validating'].includes(item.status)
          );
        });
        if (mysCompletedItems.length) {
          createDraft = false;
          setItemId(-1);
        }
      }

      if (createDraft) {
        const res = await axios.post('v2/items', {
          kind: ItemKinds.MOVE_YOUR_STUFF,
          move_id: currentMove.id,
          metadata,
          status: ItemStatuses.STARTED,
        });

        setItemId(res.data.id);
      }
      router.push(DollyRoute.Quantity);
    } catch (err) {
      toast({
        status: 'error',
        title: 'Something went wrong, please try again later.',
        duration: null,
      });
      isSubmitted.current = false;
      setIsLoading(false);
    }
  }, [
    fromValue.fullAddress,
    fromValue.value,
    toValue.fullAddress,
    toValue.value,
    setFromAddress,
    setFromHomeSize,
    setToAddress,
    setToHomeSize,
    axios,
    currentMove.id,
    setItemId,
    router,
    toast,
    itemsData,
    itemsError,
  ]);

  const handleFromReset = () => {
    setForceSelectFromAddress(false);
    setFromInputVal('');
    setFromValue(initialValue);
    setFromErrorMsg('');
    isSubmitted.current = false;
  };

  const handleToReset = () => {
    setForceSelectToAddress(false);
    setToInputVal('');
    setToValue(initialValue);
    setToErrorMsg('');
    isSubmitted.current = false;
  };

  const handleBlur = () => {
    if (fromInputVal && fromInputVal !== fromValue.value) {
      setFromInputVal(fromValue.value);
    }

    if (toInputVal && toInputVal !== toValue.value) {
      setToInputVal(toValue.value);
    }
  };

  const switchToFrom = () => {
    setToValue(fromValue);
    setFromValue(toValue);
  };

  const showSwitchButton =
    !!(toInputVal || fromInputVal) &&
    !(toErrorMsg || fromErrorMsg) &&
    !isLongDistanceError;

  const handleChange = (name: string, value: string) => {
    if (name === 'from-address') {
      setFromInputVal(value);
      if (value === '') {
        setFromValue({ fullAddress: null, value });
      }
      return;
    }

    setToInputVal(value);
    if (value === '') {
      setToValue({ fullAddress: null, value });
    }
  };

  const handleAutoSelectFirstAddress = (name: string, value: string) => {
    /** we want to set forceSelect to true if
     * the user types 5 or more chars */
    if (name === 'from-address' && value.length >= 5) {
      setForceSelectFromAddress(true);
    } else {
      setForceSelectFromAddress(false);
    }

    if (name === 'to-address' && value.length >= 5) {
      setForceSelectToAddress(true);
    } else {
      setForceSelectToAddress(false);
    }
  };

  const handleCloseModal = () => {
    isSubmitted.current = false;
    setLongDistanceError(false);
    handleFromReset();
    handleToReset();
  };

  useEffect(() => {
    if (fromValue.value && !toValue.value) {
      addresses.current.splice(0, 2, fromValue.fullAddress);
    } else if (!fromValue.value && toValue.value) {
      addresses.current.splice(0, 2, toValue.fullAddress);
    } else if (!fromValue.value && !toValue.value) {
      addresses.current.splice(0);
    } else {
      addresses.current.splice(
        0,
        2,
        fromValue.fullAddress,
        toValue.fullAddress
      );
    }
  }, [fromValue, toValue]);

  const handleSubmit = async () => {
    trackEvent({
      object: 'lets_go',
      verb: 'clicked',
      pageName: 'moving_tab',
    });

    if (!fromValue.value && !toValue.value) {
      setFromErrorMsg(emptyAddressError);
      return;
    }

    if (addresses.current.length > 0) {
      isSubmitted.current = true;
      setIsLoading(true);

      try {
        const {
          data: response,
          loading: isLoaded,
          error: queryError,
        } = await getDollyEligibility({
          variables: {
            input: {
              addresses: addresses.current,
            },
          },
        });

        if (!isLoaded) {
          const { locationIndex, errorMsg, errorType, errorDetails } =
            determineDollyEligibilityErrors({
              numAddresses: addresses.current.length,
              fromAddress: fromValue.value,
              toAddress: toValue.value,
              data: response,
              error: queryError,
            });

          if (errorType) {
            setErrorMessagesFromErrorType(
              errorType,
              locationIndex,
              errorMsg,
              errorDetails
            );
            isSubmitted.current = false;
            setIsLoading(false);
          } else {
            trackEvent({
              object: 'entered_address_dolly_eligibilty',
              verb: 'tracked',
              details: {
                eligible: true,
                fromAddress: fromValue.value,
                toAddress: toValue.value,
              },
            });
            handleRouteToMYS();
          }
        }
      } catch (queryError) {
        console.warn('getDollyEligibility error occurred', queryError);
        setIsLoading(false);
        isSubmitted.current = false;
      }
    }
  };

  return (
    <Track>
      <Box position="relative" maxWidth="465px">
        <Box marginBottom="s">
          <AddressTypeaheadInput
            dataCy="book-movers-from-address"
            data-testid="book-movers-from-address"
            value={fromInputVal}
            name="from-address"
            label="From"
            error={fromErrorMsg}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(e.target.name, e.target.value);
              handleAutoSelectFirstAddress(e.target.name, e.target.value);
            }}
            onSelectAddress={handleSelectFromAddress}
            onReset={handleFromReset}
            handleBlur={(e, forceSelectCallback) => {
              // if the target is not the reset button
              if (!e.relatedTarget) {
                forceSelectCallback(isForceSelectFromAddress);
              }
              handleBlur();
            }}
          />
        </Box>

        {showSwitchButton && (
          <SwitchButtonWrapper
            position="absolute"
            right={['xs', 'xs', 's']}
            top="xxl"
          >
            <IconButton
              dataCy="book-movers-switch"
              variant="secondary"
              icon={<ArrowsDownUp />}
              onClick={switchToFrom}
            />
          </SwitchButtonWrapper>
        )}

        <Box marginBottom="m">
          <AddressTypeaheadInput
            dataCy="book-movers-to-address"
            data-testid="book-movers-to-address"
            value={toInputVal}
            error={toErrorMsg}
            name="to-address"
            label="To"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(e.target.name, e.target.value);
              handleAutoSelectFirstAddress(e.target.name, e.target.value);
            }}
            onSelectAddress={handleSelectToAddress}
            onReset={handleToReset}
            handleBlur={(e, forceSelectCallback) => {
              // if the target is not the reset button
              if (!e.relatedTarget) {
                forceSelectCallback(isForceSelectToAddress);
              }
              handleBlur();
            }}
          />
        </Box>
        <Box maxWidth="343px">
          <Button
            size="l"
            isFluidWidth
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            {isLoading || loading ? (
              <>
                <Spinner size="s" />
              </>
            ) : (
              'Let’s go'
            )}
          </Button>
        </Box>
      </Box>
      {/* if long distance error */}
      {isLongDistanceError && (
        <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} renderAsChild>
          <ModalOverlay onClick={handleCloseModal} />
          <ModalContent maxWidth={['100%', '100%', '600px', '600px']}>
            <ModalHeader>
              <Text as="h4" variant={['mBold', 'mBold', 'lBold']}>
                Long distance move
              </Text>
              <ModalCloseButton onClick={handleCloseModal} />
            </ModalHeader>
            <ModalBody>
              <Text variant="m">
                Sorry! These locations are out of range. Please select locations
                that are both in the same city or metro area.
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Track>
  );
};
